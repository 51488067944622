@import "../node_modules/maplibre-gl/dist/maplibre-gl.css";
@import "../node_modules/@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";

body {
    margin: 0;
    padding: 0;
}

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}